<template>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label>{{ label }}</label>
    </b-col>
    <b-col cols="12" sm="7">
      <froala
        :tag="'textarea'"
        ref="froalaEditor"
        :config="config"
        v-model="inputValue"
      ></froala>
      <b-form-text v-if="desc" v-html="desc"></b-form-text>
    </b-col>
  </b-row>
</template>

<script>
import 'froala-editor/js/plugins.pkgd.min';
import VueFroala from 'vue-froala-wysiwyg';
import FroalaEditor from 'froala-editor';

export default {
  name: 'TextareaRow',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VueFroala,
  },
  props: {
    valueFromDb: { type: String, default: null },
    handle: { type: String, default: null },
    label: { type: String, default: null },
    desc: { type: String, default: null },
    placeholder: { type: String, default: null },
    enter: { type: Boolean, default: null },
  },
  data() {
    return {
      config: {
        events: {
          contentChanged: this.onContentChanged,
        },
        tooltips: true,
        height: 300,
        toolbarInline: false,
        key: 'GPD2tA9A4A5C2B2F2F1lFe1a1PVWEc1Fd1XHTHc1THMMe1NCb1tA1A1A1B1H4A1D2B1C7B4==',
        attribution: false,
        paragraphFormat: {
          H6: 'H6',
          H5: 'H5',
          H4: 'H4',
          H3: 'H3',
          H2: 'H2',
          H1: 'H1',
          N: 'Normal',
        },
        linkInsertButtons: ['linkBack'],
        linkText: true,
        pastePlain: true,
        wordPasteKeepFormatting: false,
        wordPasteModal: false,
        wordAllowedStyleProps: [],
        enter: (this.enter) ? FroalaEditor.ENTER_BR : FroalaEditor.ENTER_P,
        linkConvertEmailAddress: true,
        linkAutoPrefix: '',
        paragraphFormatSelection: true,
        linkMultipleStyles: true,
        quickInsertEnabled: false,
        charCounterCount: false,
        fontSize: ['4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '100', '101', '102', '103', '104', '105', '106', '107', '108', '109', '110', '111', '112', '113', '114', '115', '116', '117', '118', '119', '120', '121', '122', '123', '124', '125', '126', '127', '128', '129', '130', '131', '132', '133', '134', '135', '136', '137', '138', '139', '140', '141', '142', '143', '144', '145', '146', '147', '148', '149', '150'],
        lineHeights: {
          Default: '',
          0.5: '0.5',
          1: '1',
          1.15: '1.15',
          1.3: '1.3',
          1.5: '1.5',
          2: '2',
          3: '3',
        },
        // language: self.options.language || 'en',
        toolbarButtons: {
          moreText: {
            buttons: ['bold', 'textColor', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'clearFormatting'],
            buttonsVisible: 2,
          },
          moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'paragraphFormat', 'formatULSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'lineHeight', 'paragraphStyle'],
            buttonsVisible: 3,
          },
          moreRich: {
            buttons: ['insertLink', 'insertTable', 'fontAwesome', 'emoticons', 'specialCharacters', 'embedly', 'br', 'line'],
            buttonsVisible: 1,
          },
          moreMisc: {
            buttons: ['undo', 'redo', 'help'],
            align: 'right',
            buttonsVisible: 3,
          },
        },
      },
      inputValue: null,
    };
  },
  created() {
    this.inputValue = this.valueFromDb;
  },
  methods: {
    onContentChanged() {
      const froalaEditor = this.$refs.froalaEditor.getEditor();
      this.inputValue = froalaEditor.html.get();
      this.$emit('update-field', this.inputValue);
    },
  },
};
</script>

<style lang="scss">
  .fr-dropdown-list h2{
    color:#222222;
  }
</style>
